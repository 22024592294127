import React from 'react';
import { useHistory } from 'react-router-dom';
import Routes from "../routes";
import Sidebar from "./Sidebar";
import Nav from "./Nav";
import { useAuth } from '../utils/AuthContext';

function Layout(props) {
    const { authToken, logout } = useAuth();
    const history = useHistory();
      // Redirect to the login page if there is no authToken
   const token = localStorage.getItem('authToken')
  if (!token) {
    history.push('/login'); // Change '/login' to the desired login route
    return null; // Render nothing for now, as the user will be redirected
  }
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <Sidebar history={props.history} authToken={authToken} logout={logout}/>
          <div style={{width:'100%'}}>
            {props.children}
          </div>
        </div>
      </div>
    );
  }

export default Layout;
