import IconHandHeart from '../images/HandHeart.png';
import Iconexclam from '../images/exclam.png';
import IconHome from '../images/Home.png';
import IconBook_Bookmark from '../images/Book_Bookmark.png';
import IconUsersGroupRounded from '../images/UsersGroupRounded.png';
const SidebarItems = [

    {
        name: "Dashboard",
        route: '/dashboard',
        icon: IconHome,
    },
    {
        name: "Utilisateurs",
        route: '/UserList',
        icon : IconUsersGroupRounded,
    },
    {
        name: "Enquetes",
        route: '/SurveyList',
        icon : IconBook_Bookmark,
    },
    {
        name: "Associations",
        route: '/Associationlist',
        icon : IconHandHeart,
    },
    {
        name: "Echange",
        route: '/TransferList',
        icon : Iconexclam,
    },
];

export default SidebarItems;
