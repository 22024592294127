import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomModal from '../../components/modal/modal'; // Import the modal component
import ArrowBackIcon from '../../images/Square Alt Arrow Left.png';
import { useHistory, useParams } from 'react-router-dom'; // Assuming you are using React Router
import {Box,Paper, Typography ,Toolbar ,Stack, TextField, Button, Container, Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const AssociationUpdateComponent = () => {

  const history = useHistory();
  const { AssociationIdParam } = useParams(); // Assuming you are using React Router
  const [openModal, setOpenModal] = React.useState(null);
  const [errors, setErrors] = useState({});
  const [selectedPhotoName, setSelectedPhotoName] = useState('');
  const [selectedLogoName, setSelectedLogoName] = useState('');
  // State to hold the selected Association data
  const [AssociationData, setAssociationData] = useState({
    name: '',
    description: '',
    photo: null,  // Use null for the initial state of the file
    logo: null,
    url: '',
  });

// Function to fetch Association data
const fetchAssociationData = async () => {
  try {
    const response = await axios.get(`associations/${AssociationIdParam}`);
    
    console.log('API Response:', response.data); // Log the API response
    setAssociationData(response.data );
  } catch (error) {
    console.error('Error fetching Association data:', error);
  }
};

  // Function to update Association data
  const updateAssociationData =  () => {
     // Validation: Check if all required fields are filled
     const requiredFields = [
      'name',
      'description',
      'url',
      'photo',
      'logo',
    ];
    const newErrors = {};
    requiredFields.forEach((field) => {
      const fieldValue = AssociationData[field];
      if (!AssociationData[field] || (Array.isArray(AssociationData[field]) && AssociationData[field].length === 0)) {
        newErrors[field] = 'Ce champ est obligatoire';
      }else {
        // Check the type of the field based on the required type
        switch (field) {
          case 'name':
          case 'description':
            if (typeof fieldValue !== 'string') {
              newErrors[field] = 'Ce champ doit être une chaîne de caractères';
            }
            break;
          // Add more cases for other fields if needed
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const errorMessage = Object.values(newErrors).join('\n');
      handleCloseModal();
      return;
    }
    const formData = new FormData();
    formData.append('name', AssociationData.name);
    formData.append('description', AssociationData.description);
    formData.append('photo', AssociationData.photo);
    formData.append('logo', AssociationData.logo);
    formData.append('url', AssociationData.url);
    try {
       axios.patch(`associations/${AssociationIdParam}`, formData);
      // Optionally, you can handle success or show a notification here
      console.log('Association data updated successfully.');
      handleCloseModal();
      history.go(0);
    } catch (error) {
      console.error('Error updating Association data:', error);
    }
  };

  
  const handleDeleteAssociation = () => {
    axios.delete(`associations/${AssociationIdParam}`)
      .then((response) => {
        handleCloseModal();
        history.push(`/AssociationList`);
      })
      .catch((error) => {
        alert(error);
        handleCloseModal();
      });
  };
  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
  
    if (!file) {
      // Handle the case where the user didn't select a file
      return;
    }
  
    // Use FileReader to read the file and convert it to a data URL
    const reader = new FileReader();
  
    reader.onloadend = () => {
      setAssociationData((prevData) => ({
        ...prevData,
        [field]: {
          file: file,
          dataURL: reader.result,
        },
      }));
    };
  
    reader.readAsDataURL(file);
  };
  

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const isModalOpen = (modalName) => {
    return openModal === modalName;
  };
  const handleBackClick = () => {
    history.goBack();
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    handleFileChange(e, 'photo');
    // Update the state to store the selected file name
    setSelectedPhotoName(file ? file.name : '');
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    handleFileChange(e, 'logo');
    // Update the state to store the selected file name
    setSelectedLogoName(file ? file.name : '');
  };
  // Fetch Association data on component mount or when AssociationIdParam changes
  useEffect(() => {
    fetchAssociationData();
  }, [AssociationIdParam]);

  return (
    <Container className="container">
    <Toolbar>
      <Grid container >
        <Grid item xs={7}>
        <h2 className="Username_title">
          <img className="Back" src={ArrowBackIcon} alt="Back"  style={{ cursor: 'pointer'}} onClick={handleBackClick} />
          {AssociationData.name}
          </h2>
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row" spacing={1} style={{float:"right" }}>
            <Button style={{ height: 50, borderRadius: 15, border: '3px solid #E0F2ED', fontFamily: 'Outfit' }} onClick={() => handleOpenModal('updateAssociation')}>
              Sauvegarder
            </Button>
            <Button style={{ height: 50, borderRadius: 15, border: '3px solid #E0F2ED', fontFamily: 'Outfit' }} onClick={() => handleOpenModal('removeAssociation')} >
              Supprimer
            </Button>
            <Button style={{ height: 50, borderRadius: 15, border: '3px solid #E0F2ED', fontFamily: 'Outfit' }} onClick={() => history.push(`/AssociationDonors/${AssociationIdParam}`)}>
            Liste des donnateurs
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
    {/* Association INFO START */}
   <Box sx={{ paddingBottom:2}}>
     <Paper sx={{ padding: 2, borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography  variant="body1" className="myTypography">Informations générales</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Nom de l‘association</Typography>
            <TextField required error={Boolean(errors.name)} helperText={errors.name} fullWidth value={AssociationData.name} onChange={(e) => setAssociationData({ ...AssociationData, name: e.target.value })}/>
            </Grid>
            <Grid item xs={8}>
            <Typography variant="body1" className="InputTag">Description</Typography>
            <TextField required error={Boolean(errors.description)} helperText={errors.description}  fullWidth value={AssociationData.description} onChange={(e) => setAssociationData({ ...AssociationData, description: e.target.value })}/>
            </Grid>

            <Grid item xs={4}>
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image={AssociationData.photo}
        alt="photo"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Photo
        </Typography>
      </CardContent>
      <CardActions>
      <a href="#" onClick={() => document.getElementById('PhotoInputLink').click()} style={{color:'#046D93'}}>
            Cliquez ici pour télécharger une photo
      </a><hr/>
      <span>{selectedPhotoName && `Image sélectionnée: ${selectedPhotoName}`}</span>
      <input
        type="file"
        id="PhotoInputLink"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handlePhotoChange}
      />
      </CardActions>
    </Card>
            </Grid>
            <Grid item xs={4}>
       <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image={AssociationData.logo}
        alt="logo"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Logo
        </Typography>
        <Typography variant="body2" color="text.secondary"> 
     
        </Typography>
      </CardContent>
      <CardActions>
      <a href="#" onClick={() => document.getElementById('LogoInputLink').click()} style={{color:'#046D93'}}>
            Cliquez ici pour télécharger une photo
      </a><hr></hr>
      <span>{selectedLogoName && `Image sélectionnée: ${selectedLogoName}`}</span>
            <input
        type="file"
        id="LogoInputLink"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleLogoChange}
      />
      </CardActions>
    </Card>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    
      {/* MODAL START */}
<CustomModal
        open={isModalOpen('removeAssociation')}
        handleClose={handleCloseModal}
        closeAfterTransition
        handleAction={handleDeleteAssociation}
        title="Supprimer l'association"
        content={`Êtes vous sur de vouloir supprimer l'association  ${AssociationData?.description ?? 'No Title'}?`}
        button="Supprimer"
      />
<CustomModal
        open={isModalOpen('updateAssociation')}
        handleClose={handleCloseModal}
        closeAfterTransition
        handleAction={updateAssociationData}
        title="Sauvegarder l'association"
        content={`Êtes vous sur de vouloir sauvegarder l'association  ${AssociationData?.description ?? 'No Title'}?`}
        button="Sauvegarder"
      />
{/* MODAL END */}
</Container>
  );
};

export default AssociationUpdateComponent;
