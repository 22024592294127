import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import SidebarItems from "./SidebarItems";
import {Link} from "react-router-dom";
import "../style/sidebar.css";
import LogoQuid from '../images/logoQUID.png';
import { useAuth } from '../utils/AuthContext';

function Sidebar(props, {defaultActive,}) {
    const { authToken, login, logout } = useAuth();
    const location = props.history.location;
    const lastActiveIndexString = localStorage.getItem("lastActiveIndex");
    const lastActiveIndex = Number(lastActiveIndexString);
    const [activeIndex, setActiveIndex] = useState(lastActiveIndex || defaultActive);

    function changeActiveIndex(newIndex) {
        localStorage.setItem("lastActiveIndex", newIndex)
        setActiveIndex(newIndex)
    }

    function getPath(path) {
        if (path.charAt(0) !== "/") {
            return  "/" + path;
        }
        return path;
    }

    useEffect(()=> {
        const activeItem = SidebarItems.findIndex(item=> getPath(item.route) === getPath(location.pathname))
        changeActiveIndex(activeItem);
    }, [location])

    return (
      <>
      <div className="SidebarParent">
        <div style={{ position: 'fixed' }}>
        <div>
                <Link to="/">
                <img className="LogoQuid" src={LogoQuid} alt="LogoQuid" />
                </Link>
            </div>
          {SidebarItems.map((item, index) => (
            <Link to={item.route} key={item.name}>
              <div className={`SidebarItem ${index === activeIndex ? 'active' : ''}`}>
                <p><img className="CutomeSideBarIcon" src={item.icon} /> {item.name}</p>
              </div>
            </Link>
          ))}
          {authToken ? (

          <a href="#" onClick={logout} style={{ fontWeight: 'bold', position:'fixed',bottom:'5%',padding:'1%',color:'#0487B6', textDecoration : 'underline'}}>
            Déconnexion
            </a>

      ) : (
        <div>
    login
        </div>
      )}
      {/* Render the rest of your application components */}
        </div>
        <div className="behind-the-scenes" />
      </div>
    </>
    );
}

export default Sidebar;

