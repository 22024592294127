import React from 'react';
import { Typography, Container, Paper, Link, Button } from '@mui/material';
import { styled } from '@mui/system';
import LicenseAgreementPDF from './LicenseAgreement.pdf';
import Nav from '../../components/Nav';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '70vh',  // Set to 100% of the viewport height
  marginTop: '50px',
  fontFamily: 'Outfit'
});

const StyledPaper = styled(Paper)({
  padding: '20px',
  width: '90%', // Adjust the width as needed
  maxWidth: '700px', // Set a maximum width if necessary
});

const LicenseAgreement = () => {
  return (
    <>
      <Nav />
      <StyledContainer>
        <StyledPaper elevation={3}>
          <Typography variant="h5" gutterBottom>
          CONDITIONS GENERALES D’UTILISATION
          </Typography>
          <Typography variant="body1" align='left' style={{padding:"10px"}}>
            Avant de poursuivre, veuillez prendre le temps de lire et d'accepter notre contrat de licence.
            Cet accord contient des informations importantes sur les conditions d'utilisation de notre application.
            En continuant, vous reconnaissez avoir lu et accepté les conditions énoncées dans le
            l'accord de licence. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter.
          </Typography>
<hr ></hr>
          <Link href={LicenseAgreementPDF} target="_blank" rel="noreferrer">
            <Button  style={{ backgroundColor:'#0487B6',color:'#fff',height: 50, borderRadius: 15, border: '3px solid #0487B6', fontFamily: 'Outfit', padding:"10px" }}>
            CONDITIONS GENERALES D’UTILISATION
            </Button>
          </Link>
        </StyledPaper>
      </StyledContainer>
    </>
  );
};

export default LicenseAgreement;
