import React, { useEffect, useState } from 'react';
import { useParams , useHistory } from 'react-router-dom'; 
import axios from 'axios';
import {Box,Paper, Typography ,Toolbar ,Stack, TextField, Button, Container, Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import ArrowBackIcon from '../../images/Square Alt Arrow Left.png';
import { DataGrid , frFR} from '@mui/x-data-grid';
import { CSVLink } from 'react-csv';
import moment from 'moment';


const YourComponent = () => {
  const [association, setAssociation] = useState(null);
  const history = useHistory();
  const {AssociationIdParam} = useParams(); 
  useEffect(() => {
    const fetchAssociationDetails = async () => {
      try {
        const response = await axios.get(`associations/${AssociationIdParam}`);
        const associationData = response.data;
         console.log("i am here : " , response.data)
        const userDonations = associationData.transfers.reduce((acc, transfer) => {
          if (transfer.user && transfer.user._id) {
            const userId = transfer.user._id.toString();
            if (!acc[userId]) {
              acc[userId] = {
                id: userId,
                user: transfer.user,
                totalDonation: 0,
              };
            }
            acc[userId].totalDonation += parseFloat(transfer.amount);
          }
          return acc;
        }, {});

        const userDonationsArray = Object.values(userDonations);

        setAssociation({ ...associationData, userDonations: userDonationsArray });
      } catch (error) {
        console.error(error);
        // Handle error or redirect to a different page
        history.push('/error'); // Example redirect to an error page
      }
    };

    fetchAssociationDetails();
  }, [AssociationIdParam, history]);

  const columns = [
    { field: 'user._id', headerName: 'ID', flex:1,
    renderCell: (params) => (
      <div   style={{ cursor: 'pointer'}} >{params.row.user._id ? params.row.user._id.slice(19,24).toUpperCase() : 'N/A'}</div>
   
 ), },
    {
      field: 'user.first_name',
      headerName: 'Prénom',
      flex:1,
      valueGetter: (params) => params.row.user ? params.row.user.first_name : 'N/A',
    },
    {
      field: 'user.last_name',
      headerName: 'Nom',
      flex:1,
      valueGetter: (params) => params.row.user ? params.row.user.last_name : 'N/A',
    },
    {
      field: 'updatedAt',
      headerName: 'Date du don',
      flex:1,
      valueGetter: (params) => moment(params.row.updatedAt).format('YYYY-MM-DD HH:mm'),
    },
    { field: 'totalDonation', headerName: 'Montant donné', flex:1 },
  ];
  const handleBackClick = () => {
    history.goBack();
  };
  const csvData = association ? association.userDonations.map(row => ({
    ID: row._id ? row.user._id : 'N/A',
    Prénom: row.user ? row.user.first_name : 'N/A',
    Nom: row.user ? row.user.last_name : 'N/A',
    'Date du don': moment(row.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    'Montant donné': row.totalDonation,
  })) : [];

  return (
    <Container className="container">
            {association && (
<div>
<Toolbar>
      <Grid container >
        <Grid item xs={8}>
          <Typography variant="h5" component="div" className="Username_title">
          <img className="Back" src={ArrowBackIcon} alt="Back"  style={{ cursor: 'pointer'}} onClick={handleBackClick}/>
          {association.name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={2} style={{float:"right" }}>
      <CSVLink
          data={csvData}
          filename={`user_donations_${AssociationIdParam}.csv`}
          style={{color:'#046D93'}}
        >
          Télécharger la liste
        </CSVLink>
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
        <DataGrid
          rows={association.userDonations}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.id}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
    
      </div>
      )}
    </Container>
  );
};

export default YourComponent;
