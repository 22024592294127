import React , { useState }from 'react';
import axios from 'axios';
import {
  Grid,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LogoQuid from '../images/logoBlank.png';
import '../style/login.css';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
const LoginPage = () => {
  const [phone_number, setPhone_number] = useState('');
  const [password, setPassword] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const history = useHistory();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(phone_number, password);
      // If login is successful, you can redirect or update the UI accordingly
      setLoginMessage('Connexion réussie');
      const latestPage = localStorage.getItem('latestPage') || '/dashboard';
      history.push(latestPage);
    } catch (error) {
      setLoginMessage('Veuillez vérifier vos informations d‘identification.');
    }
  };
  return (
    <Paper elevation={0} style={{ boxShadow: 'none', height: '90vh' }}>
      <Grid container>
        {/* Left side with logo */}
        <Grid item xs={12} sm={6} style={{ background: '#E0F2ED' }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <img src={LogoQuid} alt="Quid" width="150" />
          </Box>
        </Grid>

        {/* Right side with login inputs */}
        <Grid item xs={12} sm={6} component={Paper} elevation={6} square>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            width="50vh"
            style={{ paddingLeft: 150, paddingRight: 100, paddingTop: 30 }}
          >
            <Typography>Connexion administrateur</Typography>
            <form style={{ width: '100%' }} onSubmit={handleLogin}>
              <TextField fullWidth required margin="normal" id="phone_number" variant="outlined" value={phone_number} onChange={(e) => setPhone_number(e.target.value)} placeholder='Identifiant'/>
              <TextField fullWidth required margin="normal" id="password" type="password" variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Mot de passe'/>
              <Button  fullWidth  margin='normal' variant="outlined" style={{color:'#fff'}}  type="submit">Se connecter</Button>
            </form>
            <Typography
              variant="body1"
              style={{ color: 'red', marginTop: 20 }}
            >
              {loginMessage && <span>{loginMessage}</span>}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoginPage;
