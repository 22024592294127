import React, { useState, useEffect } from 'react';
import { DataGrid, frFR } from '@mui/x-data-grid';
import {Typography ,Toolbar ,Grid} from '@mui/material';
import Container from '@material-ui/core/Container';
import "../../style/UserDataTable.css";
import axios from 'axios'; // Import Axios
import moment from 'moment';
const TransferList = () => {
  const [transfers, setTransfers] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    // Fetch data from the API
    setloading(true)
    axios.get('transfer/')
      .then(response => {
       // Filter out records where the receiver property is not present
       const filteredTransfers = response.data.filter(transfer => transfer.receiver !== undefined);

       // Log the filtered data to the console for inspection
       console.log('Filtered Transfers:', filteredTransfers);
       // Set the filtered data in the state
       setTransfers(filteredTransfers);
       setloading(false)

      })
      .catch(error => {
        setloading(false)
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array ensures useEffect runs only once (on mount)
  const columns = [
    { field: '_id', headerName: 'ID', width:80, 
    renderCell: (params) => { return (<div style={{ cursor: 'pointer'}} >{params.row._id.slice(19,24).toUpperCase()}</div>)}
    },
    { field: 'user.phone_number', headerName: 'Numéro d’envoi', flex: 1 ,
    renderCell: (params) => { return (<div style={{ cursor: 'pointer'}} >{params.row.user.phone_number}</div>)}
  },
    { field: 'receiver', headerName: 'Numéro de réception', flex: 1 },
    { field: 'amount', headerName: 'Somme à créditer', flex: 1 },
    { field: 'status', headerName: 'Statut', flex: 1 },
    { field: 'createdAt', headerName: 'Date d`échanges', flex: 1,
    renderCell : (params) => (
      <div>{moment(params.row.createdAt).format('DD/MM/YYYY - HH:MM')}</div>
    )
    }
  ];
  return (
    <Container className="container">
      <Toolbar>
      <Grid container >
        <Grid item xs={8}>
          <Typography variant="h5" component="div" className="Username_title">
          Liste des échanges
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
      <DataGrid
      className="data_grid"
      loading={loading}
      rows={transfers.map(transfer => ({
        ...transfer,
        'user.first_name': transfer.user.first_name,
        'user.last_name': transfer.user.last_name,
      }))}
        columns={columns}
        getRowId={(row) => row._id} 
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      />
    </Container>
  );
};

export default TransferList;
