import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import login from "./pages/login";

import UserList from "./pages/users/UserList";
import UserDetails from "./pages/users/UserDetails"

import SurveyDetails from "./pages/surveys/SurveyDetails"
import AddSurvey from "./pages/surveys/AddSurvey";
import SurveyList from "./pages/surveys/SurveyList";
import ParticipantsList from "./pages/surveys/ParticipantsList";

import Associationlist from "./pages/associations/Associationlist"
import AddAssociation from "./pages/associations/AddAssociation"
import AssociationDetails from "./pages/associations/AssociationDetails"
import donorsList from "./pages/associations/donorsList"

import TransferList from "./pages/transfer/TransferList";

import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Layout from "./components/Layout";
import LicenseAgreement from "./pages/LicenseAgreement/LicenseAgreement";

function Routes() {
    return (
<BrowserRouter>
  <Switch>
  <Route path="/LicenseAgreement" component={LicenseAgreement} /> {/* Login route */}
    <Route path="/login" component={login} /> {/* Login route */}
    <Route
      render={(props) => (
        <Layout {...props}>
          <Switch>
            {/* Dashbaord */}
            <Route path="/" exact component={Dashboard} />
            <Route path="/dashboard" exact component={Dashboard} />
            {/* Users */}
            <Route path="/UserList" component={UserList} />
            <Route path="/UserDetails/:userId" component={UserDetails} />
            {/* Surveys */}
            <Route path="/SurveyList" component={SurveyList} />
            <Route path="/SurveyDetails/:surveyIdParam" component={SurveyDetails} />
            <Route path="/participants/:surveyIdParam" component={ParticipantsList} />
            <Route path="/AddSurvey" component={AddSurvey} />
            {/* Associations */}
            <Route path="/Associationlist" component={Associationlist} />
            <Route path="/AddAssociation" component={AddAssociation} />
            <Route path="/AssociationDetails/:AssociationIdParam" component={AssociationDetails} />
            <Route path="/AssociationDonors/:AssociationIdParam" component={donorsList} />
            {/* Transfers */}
            <Route path="/TransferList" component={TransferList} />

            <Route component={NotFound} />
          </Switch>
        </Layout>
      )}
    />
  </Switch>
</BrowserRouter>
        
    )
}

export default Routes;
