import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import LogoQuid from '../images/logoBlank.png';

import "../style/navbar.css";
function Nav(props) {
    return (
      <div>
        <AppBar position="static" style={{borderBottom:'1px solid #E0F2ED', backgroundColor: '#E0F2ED', color:'#000',boxShadow: 'none'}}>
        <Toolbar style={{ justifyContent: 'center' , backgroundColor:'#E0F2ED'}}>
          <div style={{ display: 'flex' }}>
    <a href="/"><img alt="Profile Picture" src={LogoQuid} width="30px" /></a>
          </div>
        </Toolbar>
      </AppBar>
      </div>
    );
}

export default Nav;
