// Modal.js
import React from 'react';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

const CustomModal = ({ open, handleClose, handleAction, title, content ,button,loading}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Paper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 684, padding: 30, borderRadius: 15 }}>
        <Typography variant="h4">{title}</Typography>
        <Typography style={{ color: '#4B706C' }}>{content}</Typography>
        <div style={{ paddingTop: 12 }}>
          <Button
            variant="contained"
            onClick={handleAction}
            disabled={loading}
            style={{
              borderRadius: 15,
              backgroundColor: '#0487B6',
              padding: 15,
              fontFamily: 'Outfit',
            }}
          >
      {String(button)}
      {loading && <CircularProgress  style={{marginLeft:10}} color='primary' size={"1rem"}/>}
          </Button>

          <Button
            onClick={handleClose}
            style={{
              borderRadius: 15,
              backgroundColor: '#EAF6FA',
              padding: 15,
              marginLeft: 12,
              fontFamily: 'Outfit',
            }}
          >
            Annuler
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default CustomModal;
