// SurveyUpdateComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom'; // Assuming you are using React Router
import {Box,Paper, Typography ,Toolbar ,Stack, TextField, Button, Grid, Select, MenuItem, CircularProgress, Alert } from '@mui/material';
import CustomModal from '../../components/modal/modal'; // Import the modal component
import ArrowBackIcon from '../../images/Square Alt Arrow Left.png';
import Container from '@material-ui/core/Container';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { frFR } from '@mui/x-date-pickers/locales';
import "dayjs/locale/fr";
const SurveyUpdateComponent  = ({children}) =>{
  const history = useHistory();
  const { surveyIdParam } = useParams(); // Assuming you are using React Router
  const [openModal, setOpenModal] = React.useState(null);
  const [loading ,setloading] =useState(false);
  const [errormsg,seterrormsg] =useState("")
  const [errorMessages, setErrorMessages] = useState([]);
  const [errorurl, seterrorurl]=useState("");
  const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState({});
  const [enumData, setEnumData] = useState({
    USER_GENDER:[],
    MARITAL_STATUS:[],
    NATIONNALITIES:[],
    CITY:[],
    COMMUNE:[],
    COUNTRIES:[],
    OCCUPATION:[],
    SECTOR_OF_ACTIVITY:[],
    JOB_CATEGORY:[],
    CATEGORY_TYPE:[]
  });
  // State to hold the selected survey data
  const [surveyData, setSurveyData] = useState({
    title: "",
    description: "",
    points: "",
    idLimeSurvey: "",
    url: "",
    tag: "",
    tagTitle: "",
    start_at: "",
    end_at: "",
    questions: "",
    response_time:"",
    participants: [],
    masked: [],
    status: "",
    targeting: {
      minAge: "",
      maxAge: "",
      gender: [],
      countries: [],
      nationalities: [],
      cities: [],
      communities: [],
      categories: [],
      jobs: [],
      jobsCategories: [],
      marital_status: []
    }
  });
// Function to fetch survey data
const AddSurveyData = async () => {
    // Validation: Check if all required fields are filled
    const requiredFields = [
      'description',
      'points',
      'response_time',
      'url',
    ];
    const newErrors = {};
    requiredFields.forEach((field) => {
      const fieldValue = surveyData[field];
      if (!surveyData[field] || (Array.isArray(surveyData[field]) && surveyData[field].length === 0)) {
        newErrors[field] = 'Ce champ est obligatoire';
      } else {
        // Check the type of the field based on the required type
        switch (field) {
          case 'title':
          case 'description':
            if (typeof fieldValue !== 'string') {
              newErrors[field] = 'Ce champ doit être une chaîne de caractères';
            }
            break;
          case 'points':
          case 'response_time':
            if (isNaN(fieldValue)) {
              newErrors[field] = 'Ce champ doit être un nombre';
            }
            break;
          case 'url':
            if (typeof fieldValue !== 'string') {
              newErrors[field] = 'Ce champ doit être une URL valide';
            }
            break;
          // Add more cases for other fields if needed
        }
      }
    });
    

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const errorMessage = Object.values(newErrors).join('\n');
      handleCloseModal();
      return;
    }
    setloading(true)

  try {
    // Clear previous error messages
    setErrorMessages({});
    seterrormsg("")
    const response = await axios.post('surveys/create', surveyData);
    setSuccessMessage('Survey added successfully!');
    handleCloseModal();
    
    history.push("/SurveyList");
  } catch (error) {
    handleCloseModal();
    seterrormsg(error.response.data.status_message)
  }
  setloading(false)

};

  //fetch ENUM data
  const fetchEnumData = async () => {
    try {
      const response = await axios.get('surveys/targetingLists');
      setEnumData(response.data);
      console.log(enumData.CATEGORY_TYPE[0]);
    } catch (error) {
      console.error('Error fetching enum data:', error.message);
    }
  }


  // Handler function for updating nested data
  const handleTargetingChange = (key, value) => {
    setSurveyData((prevData) => ({
      ...prevData,
      targeting: {
        ...prevData.targeting,
        [key]: Array.isArray(value) ? value : [value], // Ensure value is always an array
      },
    }));
  };

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const isModalOpen = (modalName) => {
    return openModal === modalName;
  };
  const handleBackClick = () => {
    history.goBack();
  };
  const handleDateChange = (date, fieldName) => {
    setSurveyData({ ...surveyData, [fieldName]: date });
  };



  // Fetch survey data on component mount or when surveyIdParam changes
  useEffect(() => {
    fetchEnumData();
  }, [surveyIdParam]);

  return (
    <Container className="container">
      { errormsg &&<Alert severity="error">{errormsg}</Alert>}
        <form>
    <Toolbar>
      <Grid container >
        <Grid item xs={7}>
          <Typography variant="h5" component="div" className="Username_title">
          <img className="Back" src={ArrowBackIcon} alt="Back"  style={{ cursor: 'pointer'}} onClick={handleBackClick} />
          Ajouter une enquete 
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row" spacing={1} style={{float:"right" }}>
            <Button disabled={loading} style={{ backgroundColor:'#E0F2ED', height: 50, borderRadius: 15, border: '3px solid #E0F2ED', fontFamily: 'Outfit' }} onClick={() => handleOpenModal('addSurvey')}>
              Ajouter
              {loading && <CircularProgress  style={{marginLeft:10}} color='primary' size={"1rem"}/>}
            </Button>

            
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
    {/* SURVEY INFO START */}

  <Box sx={{ paddingBottom:2}}>
     <Paper sx={{ padding: 2, borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography  variant="body1" className="myTypography"> Informations générales</Typography>
            </Grid>
           
            <Grid item xs={12}>
            <Typography variant="body1" className="InputTag">Description</Typography>
            <TextField required error={Boolean(errors.description)} helperText={errors.description}  fullWidth value={surveyData.description} onChange={(e) => setSurveyData({ ...surveyData, description: e.target.value })}/>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Points à gagner</Typography>
            <TextField required error={Boolean(errors.points)} helperText={errors.points}  fullWidth value={surveyData.points} onChange={(e) => setSurveyData({ ...surveyData, points: e.target.value })}/>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Temps de réponse (en minutes)</Typography>
            <TextField required error={Boolean(errors.response_time)} helperText={errors.response_time}  fullWidth value={surveyData.response_time} onChange={(e) => setSurveyData({ ...surveyData, response_time: e.target.value })}/>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">URL de l‘enquête</Typography>
            <TextField required error={true} helperText={errors.url? 'Ce champ est obligatoire':''} fullWidth value={surveyData.url} onChange={(e) => setSurveyData({ ...surveyData, url: e.target.value })}/>
            </Grid>
               {/* **** */}
               <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Client</Typography>
            <TextField  fullWidth value={surveyData.publisher} onChange={(e) => setSurveyData({ ...surveyData, publisher: e.target.value })}/>
             </Grid>
      <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Type de catégorie</Typography>
            <Select required  fullWidth  value={surveyData ? (surveyData.tag ||"") : ""}   onChange={(e) => setSurveyData({ ...surveyData, tag: e.target.value })} >
          {enumData.CATEGORY_TYPE.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
      </Grid>
      {/* **** */}
      <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Nom de la catégorie</Typography>
            <TextField required error={true} helperText={successMessage}  fullWidth value={surveyData.tagTitle} onChange={(e) => setSurveyData({ ...surveyData, tagTitle: e.target.value.substring(0, 35)  })}/>
            <Typography variant="caption" color="textSecondary">Maximum 35 caractères</Typography>
      </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box sx={{ width: '100%', height: '100%', position: 'relative', background: 'white' , paddingBottom:2}}>
     <Paper elevation={3} sx={{ padding: 2, background: 'rgba(255, 255, 255, 0.60)', borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
      {/* **** */}
            <Grid item xs={12}>
            <Typography  variant="body1" className="myTypography">Ciblage</Typography>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Âge minimum</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="fr"  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
        <DatePicker
          value={surveyData.minAge}
          onChange={(date) => handleDateChange(date)}
          renderInput={(params) => (
            <TextField {...params} fullWidth required error={Boolean(errors.minAge)} helperText={errors.minAge} />
          )}
          format="DD/MM/YYYY" // Set the date format to "DD/MM/YYYY"
        />
      </LocalizationProvider>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Âge maximum</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr"  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
        <DatePicker
          value={surveyData.maxAge}
          onChange={(date) => handleDateChange(date)}
          renderInput={(params) => (
            <TextField {...params} fullWidth required error={Boolean(errors.maxAge)} helperText={errors.maxAge} />
          )}
          format="DD/MM/YYYY" // Set the date format to "DD/MM/YYYY"
        />
      </LocalizationProvider>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Sexe</Typography>
            <Select required multiple fullWidth value={surveyData.targeting ? surveyData.targeting.gender : []} onChange={(e) => handleTargetingChange('gender', e.target.value)} >
          {enumData.USER_GENDER.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
            </Grid>
                  {/* **** */}
                  <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Etat civil</Typography>
            <Select required multiple fullWidth value={surveyData.targeting ? surveyData.targeting.marital_status : []} onChange={(e) => handleTargetingChange('marital_status', e.target.value)} >
          {enumData.MARITAL_STATUS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Nationalité</Typography>
            <Select required multiple fullWidth  value={surveyData.targeting ? (surveyData.targeting.nationalities || []) : []}onChange={(e) => handleTargetingChange('nationalities', e.target.value)} >
          {enumData.NATIONNALITIES.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
          <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Pays</Typography>
            <Select required multiple fullWidth value={surveyData.targeting ? surveyData.targeting.countries : []} onChange={(e) => handleTargetingChange('countries', e.target.value)} >
          {enumData.COUNTRIES.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Ville</Typography>
            <Select required multiple fullWidth  value={surveyData.targeting ? (surveyData.targeting.cities || []) : []} onChange={(e) => handleTargetingChange('cities', e.target.value)} >
          {enumData.CITY.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Commune</Typography>
            <Select multiple fullWidth value={surveyData.targeting ? (surveyData.targeting.communities || []) : []} onChange={(e) => handleTargetingChange('communities', e.target.value)} disabled={!surveyData.targeting || !surveyData.targeting.cities || surveyData.targeting.cities.length === 0}>
        {enumData.COMMUNE
      .filter((city) => surveyData.targeting && surveyData.targeting.cities.includes(city.name))
      .flatMap((city) =>
        city.data.map((commune) => (
          <MenuItem key={commune} value={commune}>
            {commune}
          </MenuItem>
        ))
      )}
  </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Métier</Typography>
            <Select required multiple fullWidth  value={surveyData.targeting ? (surveyData.targeting.jobs || []) : []}onChange={(e) => handleTargetingChange('jobs', e.target.value)} >
          {enumData.OCCUPATION.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
            </Grid>
      {/* **** */}
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Secteur d'activité</Typography>
            <Select required multiple fullWidth  value={surveyData.targeting ? (surveyData.targeting.jobsCategories || []) : []}onChange={(e) => handleTargetingChange('jobsCategories', e.target.value)} >
          {enumData.SECTOR_OF_ACTIVITY.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
            </Select>
            </Grid>


      {/* **** */}
          </Grid>
        </Paper>
      </Box>
      {/* MODAL START */}
      <CustomModal
        open={isModalOpen('addSurvey')}
        handleClose={handleCloseModal}
        closeAfterTransition
        handleAction={AddSurveyData}
        title="Sauvegarder l'enquete"
        loading={loading}
        content={`Êtes vous sur de vouloir sauvegarder l'enquete  ${surveyData?.title ?? 'Non disponible'}?`}
        button="Sauvegarder"
        d
      />
{/* MODAL END */}
</form>
</Container>
  );
};

export default SurveyUpdateComponent;
