import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from "./routes";
import { AuthProvider } from '../src/utils/AuthContext'; // Import the AuthProvider
import axios from 'axios';
axios.defaults.baseURL='https://admin.appli-quid.ovh/api/'
axios.interceptors.request.use((request)=>{
  const token = localStorage.getItem('authToken');
  request.headers.Authorization = 'bearer ' + token;
  return request;
})
ReactDOM.render(
    <AuthProvider>
      <Routes />
    </AuthProvider>,
    document.getElementById('root')
  );
  
