// SurveyUpdateComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom'; // Assuming you are using React Router
import {Box,Paper, Typography ,Toolbar ,Stack, TextField, Button, Container, Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import ArrowBackIcon from '../../images/Square Alt Arrow Left.png';
import { DataGrid , frFR} from '@mui/x-data-grid';
import { CSVLink } from 'react-csv';
import moment from 'moment';

function SurveyList(props) {
  const history = useHistory();
  const { surveyIdParam } = useParams(); // Assuming you are using React Router
  const [openModal, setOpenModal] = React.useState(null);
  const [loading,setloading] =useState(true)
  // State to hold the selected survey data
  const [surveyData, setSurveyData] = useState({});
  // State to hold the selected ParticipantsList within survey data
  const [ParticipantsSurveyData, setParticipantsSurveyData] = useState([]);

const fetchSurveyData = async () => {
  try {
    setloading(true)
    const response = await axios.get('surveys/participations/'+surveyIdParam);
  
    if (response.data && Array.isArray(response.data.result) && response.data.result.length > 0) {
      if (surveyData) {
        setParticipantsSurveyData(response.data.result.filter((elt)=>elt.finished));
        setSurveyData(response.data.result.filter((elt)=>elt.finished));
      } else {
        console.error(`Survey with ID ${surveyIdParam} not found.`);
      }
    } else {
      console.error('Invalid or empty survey data format:', response.data);
    }
  } catch (error) {
    console.error('Error fetching survey data:', error);
  }
  setloading(false)
};
    

  // Define columns for the DataGrid
  const columns = [
    { field: '_id', headerName: 'ID', width: 60,
    renderCell: (params) => (
      <div  style={{ cursor: 'pointer'}} >{params.row._id.slice(19,24).toUpperCase()}</div>
   
 ),},
    { field: 'user.first_name', headerName: 'Prénom',flex:1,
    renderCell: (params) => (
      <div  style={{ cursor: 'pointer'}} >{params?.row.user?.first_name}</div>) },
    { field: 'user.last_name', headerName: 'Nom', flex:1 ,
    renderCell: (params) => (
      <div  style={{ cursor: 'pointer'}} >{params?.row.user?.last_name}</div>)
  },
    { field: 'user.email', headerName: 'E-mail', flex:1,
    renderCell: (params) => (
      <div  style={{ cursor: 'pointer'}} >{params?.row.user?.email}</div>) 
    },
    { field: 'finished', headerName: 'Statut', width:80,
     renderCell: (params) => (
      <div  style={{ cursor: 'pointer'}} >{params?.row.finished ? "Terminée": "En cours" }</div>) 
   },
    { field: 'createdAt', headerName: 'Date de participation',width:160,
    
    renderCell: (params) => (
     <div  style={{ cursor: 'pointer'}} >{moment(params.row.createdAt).format('DD/MM/YYYY, HH:MM') }</div>) },
    { field: 'completionDate', headerName: 'Date de complétion', width:160,
    renderCell: (params) => (
     <div  style={{ cursor: 'pointer'}} >{params.row.finished ? moment(params.row.completionDate).format('DD/MM/YYYY, HH:MM') :''}</div>) },
  
    // Add more columns as needed
  ];
 // CSV data for download
  const csvData = [
    ['ID', 'Prénom', 'Nom', 'Sexe','Date de naissance','Etat civil','Nationalité','Rue/Avenue','Quartier','Ville','Commune','Pays','Métier','Secteur d`activité','Adresse e-mail','Numéro de téléphone','Date de participation','Date de complétion de l`enquête'],
    ...(ParticipantsSurveyData.map(participant => [
      participant?.user?._id,
      participant?.user?.first_name,
      participant?.user?.last_name,
      mapGenderToFrench(participant?.user?.gender),
      moment(participant?.user?.birth_date).format("DD/MM/YYYY"),
      participant?.user?.marital_status,
      participant?.user?.nationality,
      participant?.user?.street,
      participant?.user?.address,
      participant?.user?.city,
      participant?.user?.commune,
      participant?.user?.country,
      participant?.user?.job,
      participant?.user?.job_sector,
      participant?.user?.email,
      participant?.user?.phone_number,
      moment(participant?.createdAt).format('DD/MM/YYYY HH:MM'),
      moment(participant?.completionDate).format('DD/MM/YYYY HH:MM'),
    ])),
  ];
// Function to map gender to French term
function mapGenderToFrench(gender) {
  switch(gender) {
    case 'male':
      return 'homme';
    case 'female':
      return 'femme';
    case 'other':
      return 'autre';
    default:
      return gender;  // Keep the original value if it doesn't match 'male', 'female', or 'other'
  }
};
const handleOpenModal = (modalName) => {
  setOpenModal(modalName);
};

const handleCloseModal = () => {
  setOpenModal(null);
};

const isModalOpen = (modalName) => {
  return openModal === modalName;
};
const handleBackClick = () => {
  history.goBack();
};

  // Fetch survey data on component mount or when surveyIdParam changes
  useEffect(() => {
    fetchSurveyData();
  }, [surveyIdParam]);

    return (
      <Container className="container">
                <Toolbar>
      <Grid container >
        <Grid item xs={8}>
          <Typography variant="h5" component="div" className="Username_title">
          <img className="Back" src={ArrowBackIcon} alt="Back"  style={{ cursor: 'pointer'}} onClick={handleBackClick} />
          {surveyData.title}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={2} style={{float:"right" }}>
          <CSVLink data={csvData} filename="participants.csv" style={{color:'#046D93'}}>
          Télécharger la liste
      </CSVLink>
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
            <DataGrid
                className="data_grid"
                rows={ParticipantsSurveyData}
                columns={columns}
                autoHeight
                loading={loading}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id} // Use the "_id" field as a custom id
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            />

        </Container>
    );
}

export default SurveyList;
