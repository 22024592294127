// AssociationUpdateComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom'; // Assuming you are using React Router
import {Box,Paper, Typography ,Toolbar ,Stack, TextField, Button, Grid } from '@mui/material';
import CustomModal from '../../components/modal/modal'; // Import the modal component
import ArrowBackIcon from '../../images/Square Alt Arrow Left.png';
import Container from '@material-ui/core/Container';
const AssociationUpdateComponent  = () =>{
  const history = useHistory();
  const [AssociationData, setAssociationData] = useState({
    name: '',
    description: '',
    photo: null,  // Use null for the initial state of the file
    logo: '',
  });
  const [openModal, setOpenModal] = React.useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [selectedPhotoName, setSelectedPhotoName] = useState('');
  const [selectedLogoName, setSelectedLogoName] = useState('');

// Function to fetch Association data
const AddAssociationData = async () => {
    // Validation: Check if all required fields are filled
    const requiredFields = [
      'name',
      'description',
      'photo',
      'logo',
    ];
    const newErrors = {};
    requiredFields.forEach((field) => {
      const fieldValue = AssociationData[field];
      if (!AssociationData[field] || (Array.isArray(AssociationData[field]) && AssociationData[field].length === 0)) {
        newErrors[field] = 'Ce champ est obligatoire';
      } else {
        // Check the type of the field based on the required type
        switch (field) {
          case 'name':
          case 'description':
            if (typeof fieldValue !== 'string') {
              newErrors[field] = 'Ce champ doit être une chaîne de caractères';
            }
            break;
          // Add more cases for other fields if needed
        }
      }
    });
    

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      const errorMessage = Object.values(newErrors).join('\n');
      handleCloseModal();
      return;
    }

    // Clear previous error messages
    setErrorMessages({});
    try {
      const formData = new FormData();
      formData.append('name', AssociationData.name);
      formData.append('description', AssociationData.description);
      formData.append('status', AssociationData.status);
      formData.append('photo', AssociationData.photo);
      formData.append('logo', AssociationData.logo);
      formData.append('url', AssociationData.url);

      const response = await axios.post('associations/', formData );

      if (!response.ok) {
        throw new Error('Failed to create association');
      }

      const data = await response.json();
      console.log('Association created:', data);
      setSuccessMessage('Association added successfully!');
      handleCloseModal();
      history.push("/AssociationList");

  } catch (error) {
    if (error.response) {
      // If response has validation errors, update the error messages
      if (error.response.data.errors) {
        setErrorMessages(error.response.data.errors);
        handleCloseModal();
      } else {
        setSuccessMessage(error.response && error.response.data ? error.response.data.status_message : error.message);
        handleCloseModal();
      }
    } else {
      setSuccessMessage(error.response && error.response.data ? error.response.data.status_message : error.message);
      handleCloseModal();
    }
    console.error('Error adding Association:', error.response ? error.response.data : error.message);
    handleCloseModal();
  }
};



const handleInputChange = (e) => {
  setAssociationData({ ...AssociationData, [e.target.name]: e.target.value });
};

const handleFileChange = (e, field) => {
  const file = e.target.files[0];
  setAssociationData({ ...AssociationData, [field]: file });
};


  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const isModalOpen = (modalName) => {
    return openModal === modalName;
  };
  const handleBackClick = () => {
    history.goBack();
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    handleFileChange(e, 'photo');
    // Update the state to store the selected file name
    setSelectedPhotoName(file ? file.name : '');
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    handleFileChange(e, 'logo');
    // Update the state to store the selected file name
    setSelectedLogoName(file ? file.name : '');
  };


  return (
    <Container className="container">
        <form>
    <Toolbar>
      <Grid container >
        <Grid item xs={7}>
          <Typography variant="h5" component="div" className="Username_title">
          <img className="Back" src={ArrowBackIcon} alt="Back"  style={{ cursor: 'pointer'}} onClick={handleBackClick} />
          Ajouter une association
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row" spacing={1} style={{float:"right" }}>
            <Button style={{ backgroundColor:'#E0F2ED', height: 50, borderRadius: 15, border: '3px solid #E0F2ED', fontFamily: 'Outfit' }} onClick={() => handleOpenModal('addAssociation')}>
              Ajouter
            </Button>
            
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
    {/* Association INFO START */}
  <Box sx={{ paddingBottom:2}}>
     <Paper sx={{ padding: 2, borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography  variant="body1" className="myTypography">Informations générales</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Nom de l’association</Typography>
            <TextField
        name="name"
        value={AssociationData.name}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        error={Boolean(errors.name)} helperText={errors.name}
      />
            </Grid>
            <Grid item xs={8}>
            <Typography variant="body1" className="InputTag">Description</Typography>
            <TextField
        name="description"
        value={AssociationData.description}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        error={Boolean(errors.description)} helperText={errors.description}
      />
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Photo</Typography>
            <a href="#" onClick={() => document.getElementById('PhotoInputLink').click()} style={{color:'#046D93'}}>
            Cliquez ici pour télécharger une photo
      </a><hr></hr>
      <span>{selectedPhotoName && `Image sélectionnée: ${selectedPhotoName}`}</span>
      <input
        type="file"
        id="PhotoInputLink"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handlePhotoChange}
      />

            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" className="InputTag">Logo</Typography>
            <a href="#" onClick={() => document.getElementById('LogoInputLink').click()} style={{color:'#046D93'}}>
            Cliquez ici pour télécharger une photo
      </a><hr></hr>
      <span>{selectedLogoName && `Image sélectionnée: ${selectedLogoName}`}</span>
            <input
        type="file"
        id="LogoInputLink"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleLogoChange}
      />
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {/* MODAL START */}
      <CustomModal
        open={isModalOpen('addAssociation')}
        handleClose={handleCloseModal}
        closeAfterTransition
        handleAction={AddAssociationData}
        title="Sauvegarder l'association"
        content={`Êtes vous sur de vouloir sauvegarder l'association  ${AssociationData?.name ?? 'Non disponible'}?`}
        button="Sauvegarder"
      />
{/* MODAL END */}
</form>
</Container>
  );
};

export default AssociationUpdateComponent;
