import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {Typography ,Toolbar ,Stack, Button, Grid } from '@mui/material';
import Delete from '../../images/TrashBin.png';
import UserGroupeIcon from '../../images/participantlist.png';
import "../../style/UserDataTable.css";
import { DataGrid , frFR} from '@mui/x-data-grid';
import CustomModal from '../../components/modal/modal'; // Import the modal component
import Container from '@material-ui/core/Container';
const AssociationsList = () => {
  const [associations, setAssociations] = useState([]);
  const history = useHistory();
  const [openModal, setOpenModal] = React.useState(null);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    // Fetch associations data from your backend

    const fetchAssociationsData = async () => {
      setloading(true)
      try {
        const response = await axios.get('associations'); // Replace with your API endpoint
        setAssociations(response.data);
        console.log(associations);
      } catch (error) {
        console.error('Error fetching associations data:', error);
      }
      setloading(false)
    };

    fetchAssociationsData();
  }, []);
  console.log(associations)
  const columns = [
    {         
    field: 'id',
    headerName: 'ID',
    width:80,
    renderCell: (params) => (
      <div style={{ cursor: 'pointer'}} >{params.row.id.slice(19,24).toUpperCase()}</div>
 )
    },
    { field: 'name', headerName: 'Nom', flex: 2 },
    { field: 'description', headerName: 'Description', flex: 2.7 },
    { field: 'totalPoints', headerName: 'Somme des dons ', flex: 1 },
    { field: 'transferCount', headerName: 'Nombre de dons', flex: 1 },
    { field: 'status', headerName: 'Statut', width:90 },
    { field: 'action', headerName: '', width:150 ,
    renderCell: (params) => (
      <div  className="action">
            <button className="delete_btn"  onClick={() => handleOpenModal('removeAssociation',params.row.id)}  style={{ cursor: 'pointer'}} disabled={params.row.status === 'Supprimée'}>
      <img style={{width:24,  paddingLeft:40 , opacity: params.row.status === 'Supprimée' ? '0.3' : 'inherit'}} src={Delete} alt="Delete"  />
    </button>
    <button className="delete_btn"  onClick={() => handleOpenDonorsClick(params.row.id)}   style={{ cursor: 'pointer'}} ><img style={{width:24 }} src={UserGroupeIcon} alt="Détails donateurs" /></button>
</div>
    )},
  ];

  const rows = Array.isArray(associations)
  ? associations.map((association) => ({
      id: association._id,
      name: association.name,
      description: association.description,
      totalPoints: calculateTotalPoints(association.transfers),
      transferCount: association.transfers.length,
      status: association.status,
      users: Array.isArray(association.transfers)
        ? association.transfers.map((transfer) => transfer.user).join(', ')
        : '',
    }))
  : [];
  
  const handleDeleteAssociation = () => {
    // Get the URLSearchParams object for the current URL
    const searchParams = new URLSearchParams(window.location.search);
    const AssociationId = searchParams.get('AssociationId');
    alert(AssociationId);
    axios.delete(`associations/${AssociationId}`)
      .then((response) => {
        handleCloseModal();
      history.go(0)
      })
      .catch((error) => {
        alert(error);
        handleCloseModal();
      });
  };

  const handleOpenAssoClick = (id) => {
    // Handle the row click event, e.g., navigate to a user profile page
    history.push(`/AssociationDetails/${id}`);
    // You can use a router like React Router to navigate to another page.
  };

  const handleOpenDonorsClick = (id) => {
    // Handle the row click event, e.g., navigate to a user profile page
    history.push(`/AssociationDonors/${id}`);
    // You can use a router like React Router to navigate to another page.
  };

  const handleOpenModal = (modalName,AssociationId) => {
    const urlWithParam = `${window.location.pathname}?AssociationId=${AssociationId}`;
    window.history.pushState({}, '', urlWithParam);
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const isModalOpen = (modalName) => {
    return openModal === modalName;
  };

  const handleCellClick = (params, event) => {
    const isActionColumn = params.field === 'action';

    if (isActionColumn) {
      // Prevent row click action for the 'action' column
      event.stopPropagation();
    } else {
      // Navigate to the desired page for non-action clicks
      history.push(`/AssociationDetails/${params.row.id}`);
    }
  };
  return (
    <Container className="container">
          <Toolbar>
      <Grid container >
        <Grid item xs={8}>
          <Typography variant="h5" component="div" className="Username_title">
          Associations <span class="badge">{associations.length}</span>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={2} style={{float:"right" }}>
          <Button style={{ backgroundColor:'#0487B6',color:'#fff',height: 50, borderRadius: 15, border: '3px solid #0487B6', fontFamily: 'Outfit'}} onClick={() => history.push('/AddAssociation')}>
              Ajouter association
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
    <DataGrid
                className="data_grid"
                rows={rows}
                columns={columns}
                autoHeight
                pageSize={10}
                loading={loading}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.id} // Use the "_id" field as a custom id
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                onCellClick={handleCellClick}
            />
                  {/* MODAL START */}
<CustomModal
        open={isModalOpen('removeAssociation')}
        handleClose={handleCloseModal}
        closeAfterTransition
        handleAction={handleDeleteAssociation}
        title="Supprimer l'association"
        content={`Êtes vous sur de vouloir supprimer l'association ?`}
        button="Supprimer"
      />
    </Container>
  );
};

// Function to calculate total points from an array of transfers
const calculateTotalPoints = (transfers) => {
  console.log("kkkkkk",transfers)
  return transfers.reduce((total, transfer) => total + parseInt(transfer.points), 0);
};

export default AssociationsList;
