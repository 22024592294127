import React , { useEffect, useState }from 'react';
import axios from 'axios'; // Import Axios
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Toolbar ,Stack,Button } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';
import '../style/dashboard.css';  // Import the generated CSS file
import userIcon from '../images/UsersGroupRounded.png';
import transferIcon from '../images/Left_Right.png';
import dollarIcon from '../images/dollar.png';
import pointsIcon from '../images/exclam.png';
import surveyDoneIcon from '../images/surveydone.png';
import surveyUnDoneIcon from '../images/Book_Bookmark.png';
import donorsIcon from '../images/HandHeart.png';
import clockIcon from '../images/clock.png';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  const history = useHistory();
  const [UserData, setUserData] = useState([]);
  const [participations, setParticipations] = useState([]);
  const [transfersCount, setTransfersCount] = useState("");
  const [donsCount, setDonsCount] = useState("");
  const [totalPoints, setTotalPoints] = useState("");
  const [totalAmounts, setTotalAmounts] = useState("");
  const [finishedSurveys , setfinishedSurveys] = useState("");
  const [unfinishedSurveys , setunfinishedSurveys] = useState("");
  const [surveys , setsurveys] = useState([]);
  const [surveyslength , setsurveyslength] = useState("");

  

 

  async function getStat(){
     try{
       const {data } =await axios.get("surveys/stats")
       setUserData(data.userscount)
       setTotalAmounts(data.transfersValues);
       setsurveys(data.lastsurveys);
       setsurveyslength(data.surveysonline);
       setfinishedSurveys(data.finshedsurveys)
       setunfinishedSurveys(data.unfinshedsurveys)
       setTotalPoints(data.pointswinned);
       setTransfersCount(data.transfersCount);
       setDonsCount(data.donsCount)
     }
     catch(e){}
  }


  useEffect(() => {
    getStat();
  }, []);

  const handleSurveyClick = (surveyId) => {
    // Redirect to the detailed view of the survey using the surveyId
    history.push(`/SurveyDetails/${surveyId}`);
  };
  
  return (
    <Container className="container">
  <Toolbar>
      <Grid container >
        <Grid item xs={8}>
          <Typography variant="h4" component="div" className="Username_title">
          Dashboard
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={2} style={{float:"right" }}>
          <Button style={{ backgroundColor:'#0487B6',color:'#fff',height: 50, borderRadius: 15, border: '3px solid #0487B6', fontFamily: 'Outfit' }} onClick={() => history.push('/AddSurvey')} >
        Ajouter une enquête
            </Button>

          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
      <Box my={4} className="box">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3} className="gridItem animatedCard">
            <Card className="card">
              <CardContent className="cardContent">
              <img src={userIcon} style={{width:60, padding:20}} /> 
              <Typography variant="h4" component="h1" className="value">
                { UserData}
                </Typography>
                <Typography color="textSecondary" gutterBottom className="title">
                Nombre d'utilisateurs actifs
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="gridItem animatedCard">
            <Card className="card">
              <CardContent className="cardContent">
              <img src={surveyUnDoneIcon} style={{width:60,padding:20}} /> 
              <Typography variant="h4" component="h2" className="value">
                  {surveyslength}
                </Typography>
                <Typography color="textSecondary" gutterBottom className="title">
                Nombre d'enquêtes en ligne
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="gridItem animatedCard">
            <Card className="card">
              <CardContent className="cardContent">
              <img src={surveyDoneIcon} style={{width:60,padding:20}} /> 
              <Typography variant="h4" component="h2" className="value">
                  {finishedSurveys}
                </Typography>
                <Typography color="textSecondary" gutterBottom className="title">
                Nombre de réponses
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="gridItem animatedCard">
            <Card className="card">
              <CardContent className="cardContent">
              <img src={clockIcon} style={{width:60,padding:20}} /> 
              <Typography variant="h4" component="h2" className="value">
                  {unfinishedSurveys}
                </Typography>
                <Typography color="textSecondary" gutterBottom className="title">
                Nombres d'enquêtes en cours
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="gridItem animatedCard">
            <Card className="card">
              <CardContent className="cardContent">
              <img src={pointsIcon} style={{width:50,padding:20}} /> 
              <Typography variant="h4" component="h2" className="value">
              {totalPoints}
                </Typography>
                <Typography color="textSecondary" gutterBottom className="title">
                Nombre de points gagnés
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="gridItem animatedCard">
            <Card className="card">
              <CardContent className="cardContent">
              <img src={dollarIcon} style={{width:50,padding:20}} /> 
              <Typography variant="h4" component="h2" className="value">
                  ${totalAmounts}
                </Typography>
                <Typography color="textSecondary" gutterBottom className="title">
                Somme totale échangée                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="gridItem animatedCard">
            <Card className="card">
              <CardContent className="cardContent">
              <img src={transferIcon} style={{width:40,padding:20}} /> 
              <Typography variant="h4" component="h2" className="value">
                {transfersCount}
                </Typography>
                <Typography color="textSecondary" gutterBottom className="title">
                Nombre d'échanges réalisés
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8} md={3} className="gridItem animatedCard">
            <Card className="card">
              <CardContent className="cardContent">
              <img src={donorsIcon} style={{width:60,padding:20}} /> 
              <Typography variant="h4" component="h2" className="value">
                {donsCount}
                </Typography>
                <Typography color="textSecondary" gutterBottom className="title">
                Nombre de dons réalisés
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={30} sm={14} md={12} className="gridItem animatedCard">
            <Card className="card">
              <CardContent className="">
              <Typography variant="h4" component="h2" className="value">
              Dernières enquêtes
                </Typography>
      <List>
        {surveys.map((survey) => (
          <ListItem key={survey._id} >
            <ListItemText
              primary={survey.title}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="textSecondary">
                    Description: {survey.description}
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2" color="textSecondary">
                    Points: {survey.points}
                  </Typography>
                  {/* Add more details as needed */}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;
