import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import "../../style/UserDetails.css";
import {Grid} from "@material-ui/core";
import {Toolbar ,Stack, CircularProgress } from '@mui/material';
import {Modal} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '../../images/Square Alt Arrow Left.png';
import Container from '@material-ui/core/Container';
import moment from 'moment';
function Page2(props) {
  const [userData, setUserData] = useState({});
  const { userId } = useParams();
  const history = useHistory();
  const [deleteloading,setdeleteloading]=useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    // Make an API request to fetch user data using Axios
    axios.get(`users/${userId}`)
      .then((response) => {
        // Update the state with the fetched data
        setUserData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, []);
  const handleOpen = () => {

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteUser = () => {
    // Send an Axios DELETE request to the server to delete the user
    setdeleteloading(true)
    axios.delete(`users/${userId}`)
      .then((response) => {
        setdeleteloading(false)
        handleClose();
        history.push(`/UserList`);

      })
      .catch((error) => {
        setdeleteloading(false)
        alert('Error deleting user:', error);
        handleClose();
      });
  };
  function handleBackClick() {
    // Handle the row click event, e.g., navigate to a user profile page
    history.goBack();
    // You can use a router like React Router to navigate to another page.
  };
  const customStyles = {
    myTypography: {
      fontFamily: 'Outfit', // Specify the "Outfit" font
      color: 'black', // Change the text color to blue
      paddingBottom:1,
      // Add other custom styles as needed
    },
    InputTag: {
      fontFamily: 'Outfit_regular', // Specify the "Outfit" font
      color: 'black', // Change the text color to blue
      fontsize:500,
      paddingBottom:1,
      // Add other custom styles as needed
    },
  };
  const commonTextFieldStyle = {
    '&.MuiTypography-root': {
      borderRadius: 5, // Border radius
      border: '2px solid #eee', // Border color
      padding:2,
      '&:hover': {
        border: '2px solid #E0F2ED', // Border color on hover
      },
      '&.Mui-focused': {
        border: '2px solid #E0F2ED', // Border color when focused
      },
    },
  };
    return (
      <Container className="container">
    <Toolbar>
      <Grid container >
        <Grid item xs={7}>
          <Typography variant="h5" component="div" className="Username_title">
          <img className="Back" src={ArrowBackIcon} alt="Back"  style={{ cursor: 'pointer'}} onClick={handleBackClick} />
          {userData.first_name} {userData.last_name}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row" spacing={1} style={{float:"right" }}>
            <Button disabled={userData.status=='Supprimée'} style={{ backgroundColor:'#E0F2ED', height: 50, borderRadius: 15, border: '3px solid #E0F2ED', fontFamily: 'Outfit' }}onClick={() => handleOpen()}>
            Supprimer l'utilisateur
            
            </Button>
            
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>


     <Box sx={{ width: '100%', height: '100%', position: 'relative', background: 'white' , paddingBottom:2}}>
     <Paper elevation={3} sx={{ padding: 2, background: 'rgba(255, 255, 255, 0.60)', borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="body1" sx={customStyles.myTypography}> Informations personnelles</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>ID</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> 
            {userData && userData._id ? userData._id.slice(19, 24).toUpperCase() : ''}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Prénom</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.first_name}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Nom</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.last_name}</Typography>
            </Grid>
            
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Post-Nom</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.first_name } {userData.last_name }</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Sexe</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.gender}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Date de naissance</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {moment(userData.birth_date).format('DD/MM/YYYY')}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Etat civil</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.marital_status}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Nationalité</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.nationality}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box sx={{ width: '100%', height: '100%', position: 'relative', background: 'white',paddingBottom:2 }}>
      <Paper elevation={3} sx={{ padding: 2, background: 'rgba(255, 255, 255, 0.60)', borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="body1" sx={customStyles.myTypography}> Adresse </Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Rue/Avenue</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.street}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Quartier</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.address}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Ville</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.city}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Commune / arrondissement</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.commune}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Pays</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.city}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box sx={{ width: '100%', height: '100%', position: 'relative', background: 'white',paddingBottom:2 }}>
     <Paper elevation={3} sx={{ padding: 2, background: 'rgba(255, 255, 255, 0.60)', borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="body1" sx={customStyles.myTypography}> Fonction </Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Métier</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.job}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Secteur d’activité</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.job_sector}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Catégorie</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.category}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

     <Box sx={{ width: '100%', height: '100%', position: 'relative', background: 'white',paddingBottom:2 }}>
     <Paper elevation={3} sx={{ padding: 2, background: 'rgba(255, 255, 255, 0.60)', borderRadius: 2, border: '1px #90D2AE solid', justifyContent: 'flex-start', alignItems: 'center', gap: 3, display: 'flex', boxShadow: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
            <Typography variant="body1" sx={customStyles.myTypography}> Contact </Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Adresse e-mail</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.email}</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="body1" sx={customStyles.InputTag}>Numéro de téléphone</Typography>
            <Typography variant="body1" sx={commonTextFieldStyle}> {userData.phone_number}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Paper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 684,p: 2 , borderRadius:15 ,padding:30}}>
          <Typography variant="h4">Supprimer l’utilisateur</Typography>
          <Typography
             style={{
              color:'#4B706C'
            }}
          >Êtes vous sur de vouloir supprimer l’utilisateur “{userData.first_name} {userData.last_name}” ? </Typography>
          <div style={{paddingTop:12}}>
            <Button variant="contained" onClick={handleDeleteUser}
                  style={{
                    borderRadius: 15,
                    backgroundColor:'#0487B6',
                    padding:15,
                    fontFamily: 'Outfit',
                  }}
            >Supprimer  { deleteloading && <CircularProgress style={{marginLeft:10}} color='inherit' size="1rem" />}</Button>
            
            <Button onClick={handleClose}
                              style={{
                                borderRadius: 15,
                                backgroundColor:'#EAF6FA',
                                padding:15,
                                marginLeft:12,
                                fontFamily: 'Outfit',
                              }}
            >Annuler</Button>
          </div>
        </Paper>
      </Modal>
    </Container>
    );
}

export default Page2;
