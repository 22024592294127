import { CardContent, Typography } from '@mui/material';
import React from 'react';

const NotFound = props => {
    return (
      <div className='container-fluid' style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%'}}>
        <CardContent className="cardContent">
              <Typography variant="h1" component="h1" className="value">
                404
                </Typography>
               
              </CardContent></div>  
    );
};

export default NotFound;
